import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { getAppItem } from "Components/AppChip";
import GoBack from "Components/GoBack";
import { AvironLoading } from "Components/Icons/AvironLoading";
import MemberLink from "Components/MembershipPage/MemberLink";
import PageTitle from "Components/PageTitle";
import { useTrackingData } from "Components/TrackingData";
import UnderlineButton from "Components/base/Button/UnderLineButton";
import { CONSTANTS } from "consts";
import { MEMBERSHIP_INTERVAL, MEMBERSHIP_TRACKING_TYPE, MembershipProductType } from "consts/membership";
import { UserAppContext } from "contexts";
import get from "lodash/get";
import set from "lodash/set";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useWindowSize } from "scripts/useWindowSize";
import { capitalizeFirstLetter } from "scripts/utils/string";
import { gameServerApi } from "services/gameServerV2";
import {
    GetAvironSubscriptionQuery,
    useCancelUserSubscriptionMutation,
    useChangeUserSubscriptionPlanMutation,
    useCheckCancelOfferAvaiableSubscriptionQuery,
    useCheckUserReceiveMembershipOfferQuery,
    useClaimCancelOfferSubscriptionMutation,
    useFitplanChooseSubscriptionMutation,
    useGetAvironSubscriptionQuery,
    useGetAvironSubscriptionReceiptsLazyQuery,
    usePausePaymentSubscriptionMutation,
    usePlansQuery,
    useReactiveUserSubscriptionMutation,
    useReactiveUserSubscriptionV2Mutation,
    useResumePaymentSubscriptionMutation,
    useSharedQuery,
    useTrackingSubscriptionActionMutation
} from "services/webServer";
import Swal from "sweetalert2";
import { vwo } from "tracking/vwo";
import { formatAppIds } from "utils/membership";
import { getUrlVars } from "utils/url";
import useHandleActivePage from "utils/useHandleActivePage";
import { getStatus } from "../Main/MembershipsMain.constant";
import { handleCheckIsFreeMembership } from "../utils/handleCheckIsFreeMembership";
import CancelOfferMembershipModal from "./CancelOfferMembership";
import ChangeMembershipModal from "./ChangeMembership";
import style from "./Detail.module.scss";
import EnrollLifetimeWarrantyModal from "./EnrollLifetimeWarranty";
import FitPlanChooseMembershipModal from "./FitPlanChooseMembership";
import KlaviyoUpgradeMembershipModal from "./KlaviyoUpgradeMembership";
import KlaviyoUpgradeMembershipSuccessModal from "./KlaviyoUpgradeMembership/SuccessModal";
import ReactiveMembershipModal from "./ReactiveMembershipNotice";
import ReactiveMembershipWithoutPromoModal from "./ReactiveMembershipNoticeWithoutPromo";
import Receipts from "./Receipts";
import ResumeMembershipModal from "./ResumeMembership";
import "./style.scss";


const initModalState = {
    openChange: false,
    openUpgradePlan: false,
    openUpgradePlanSuccess: false,
    openCancel: false,
    openSuccess: false,
    openError: false,
    openPause: false,
    openResume: false,
    openCancelOffer: false,
    openCancelNotice: false,
    openCancelOfferSuccess: false,
    openEnrollLifetimeWarranty: false,
    openReactive: false,
    openReactiveWithoutPromo: false,
    openFitPlanChooseMembership: false,
    cancelData: {
        reason: "",
        reasonDetail: "",
    },
};
const ROWS_PER_PAGE: number = 10;

export interface ISubmitPauseSubscription {
    resumesAt: string;
    reason: string;
    note: string;
}


export default function MembershipDetail() {
    useHandleActivePage(CONSTANTS.SIDEBAR_PAGEID.MEMBERSHIPS);
    const params: any = useParams();
    const history = useHistory();
    const location = useLocation();
    const { auth } = useContext(UserAppContext);
    const userId = useMemo(() => auth?.id, [auth]);

    const [changeUserSubscription] = useChangeUserSubscriptionPlanMutation();
    const [cancelUserSubscription] = useCancelUserSubscriptionMutation();
    const [pausePaymentSubscription] = usePausePaymentSubscriptionMutation();
    const [resumePaymentSubscription] = useResumePaymentSubscriptionMutation();
    const [claimCancelOffer] = useClaimCancelOfferSubscriptionMutation();
    const [reactiveUserSubscription] = useReactiveUserSubscriptionMutation();
    const [fitplanChooseSubscription] = useFitplanChooseSubscriptionMutation();
    const [trackingSubscriptionAction] =
        useTrackingSubscriptionActionMutation();
    const {
        trackingPauseOrCancelMembershipClick,
        trackingCancelMembershipClick,
    } = useTrackingData(params.id);
    const [modalState, setModalState] = React.useState(initModalState);
    const [pagination, setPagination] = React.useState({
        totalPage: 1,
        currentPage: 0,
    });
    const [membershipData, setMembershipData] = React.useState<any>({});
    const [isOwnerMembership, setIsOwnerMembership] = useState(false);
    const windowSize = useWindowSize();
    const [reactiveUserSubscriptionV2] =
        useReactiveUserSubscriptionV2Mutation();
    const [isReCall, setIsReCall] = useState(false);

    const { data: checkUserReceivedMembershipOffer } =
        useCheckUserReceiveMembershipOfferQuery({
            variables: {
                userId: userId,
            },
            skip: Boolean(!userId),
            fetchPolicy: "no-cache",
        });

    const resetState = () => {
        setModalState(initModalState);
    };

    const [state, setState] = React.useState({
        receipts: [],
        totalCount: 0,
        processing_change_plan: false,
        change_plan_invoice_url: undefined,
    });

    const { data: userData } = useSharedQuery();
    const { data: planData, loading: loadingPlan } = usePlansQuery();
    const { data: checkCancelOfferData, refetch: refetchCheckCancelOfferData } =
        useCheckCancelOfferAvaiableSubscriptionQuery({
            fetchPolicy: "no-cache",
        });

    const { data, loading, error, refetch } = useGetAvironSubscriptionQuery({
        variables: {
            id: params.id,
        },
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        const queryString = getUrlVars();
        const isUpgradePlan = sessionStorage.getItem(`isUpgradePlan-${params.id}`);
        if (
            (queryString.action && queryString.action === "upgrade-plan") &&
            data?.avironSubscription?.plan?.type === "stripe" &&
            (get(data, "avironSubscription.plan.productId") === MembershipProductType.membership
                || get(data, "avironSubscription.plan.productId") === MembershipProductType.membershipWithWarranty) &&
            !isUpgradePlan &&
            data?.avironSubscription?.plan?.interval === "monthly" &&
            (data?.avironSubscription?.state === "active" || data?.avironSubscription?.state === "inactive")
        ) {
            setModalState({
                ...initModalState,
                openUpgradePlan:
                    true,
            });
        }
    }, [data]);

    // useEffect(() => {
    //     setModalState({
    //         ...initModalState,
    //         openUpgradePlan:
    //             true,
    //     });
    // }, [data]);

    const [
        getReceiptData,
        {
            data: receiptsData,
            refetch: refetchReceipts,
            loading: receiptLoading,
        },
    ] = useGetAvironSubscriptionReceiptsLazyQuery({
        fetchPolicy: "no-cache",
        variables: {
            id: params.id,
            first: ROWS_PER_PAGE,
            offset: pagination.currentPage * ROWS_PER_PAGE,
        },
        notifyOnNetworkStatusChange: true,
    });

    // checl if user is owner to call get receipt data
    useEffect(() => {
        if (isOwnerMembership) {
            getReceiptData();
        }
    }, [isOwnerMembership]);

    const appId = localStorage.getItem("appId");

    useEffect(() => {
        if (
            [
                CONSTANTS.APP.C2_ROWER,
                CONSTANTS.APP.C2_SKIERG,
                CONSTANTS.APP.C2_BIKEERG,
            ].indexOf(Number(appId)) !== -1
        ) {
            history.push(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS);
        }
    }, [appId]);

    useEffect(() => {
        gameServerApi.user
            .getMembershipFamilly({ orgId: params.id })
            .then((res) => {
                setMembershipData(res.data.currentMembership);
                if (
                    res?.data?.currentMembership?.owner?.customId ===
                    userData?.currentUser?.id
                ) {
                    setIsOwnerMembership(true);
                } else {
                    setIsOwnerMembership(false);
                }
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    history.push(CONSTANTS.NAVIGATION_URL.NOT_FOUND);
                }
                setIsOwnerMembership(false);
            });
    }, [userData, isReCall]);

    const isFreeMembership = handleCheckIsFreeMembership(membershipData?.planId);

    const isCommercialMembership = useMemo(() => {
        if (typeof data === "undefined") return false;
        return get(data, "avironSubscription.plan.customerType") === "commercial";
    }, [data])

    const isReceivedMembershipOffer = useMemo(() => {
        if (typeof checkUserReceivedMembershipOffer === "undefined")
            return undefined;

        return Boolean(
            checkUserReceivedMembershipOffer?.checkUserReceiveMembershipOffer
        );
    }, [checkUserReceivedMembershipOffer]);


    const isCanReactive = useMemo(() => {
        if (
            isFreeMembership ||
            isCommercialMembership ||
            typeof isReceivedMembershipOffer === "undefined" ||
            Boolean(isReceivedMembershipOffer)
        ) {
            return false;
        }
        const queryString = getUrlVars();
        const canReactive = sessionStorage.getItem("reactive");
        if (
            ((queryString.source && queryString.source === "qr-code") ||
                (canReactive && canReactive === "true")) &&
            ["ended", "canceled"].includes(get(data, "avironSubscription.state")) &&
            get(data, "avironSubscription.plan.type") === "stripe" &&
            get(data, "avironSubscription.plan.isRecurring") &&
            get(data, "avironSubscription.remark.reactive") !== true &&
            isOwnerMembership
        ) {
            const isShowNoticeReactive = sessionStorage.getItem(
                `reactive:${params.id}`
            );
            if (!isShowNoticeReactive) {
                setModalState({ ...initModalState, openReactive: true });
                sessionStorage.setItem(`reactive:${params.id}`, "true");
            }
            return true;
        }
        return false;
    }, [data, isFreeMembership, isOwnerMembership, isCommercialMembership, isReceivedMembershipOffer]);

    const handleCloseNoticeReactiveModal = () => {
        sessionStorage.setItem(`reactive:${params.id}`, "true");
        setModalState(initModalState);
    };

    const handleTrackingCancellationInitiated = () => {
        const subscriptionType =
            data?.avironSubscription?.plan?.interval ===
                MEMBERSHIP_TRACKING_TYPE.MONTHLY
                ? MEMBERSHIP_TRACKING_TYPE.MONTHLY
                : MEMBERSHIP_TRACKING_TYPE.ANNUAL;
        vwo.trackingCancellationInitiated({
            type: subscriptionType,
        })
    };

    const isCanReactiveWithoutPromo = useMemo(() => {
        if (
            isFreeMembership ||
            isCommercialMembership ||
            typeof isReceivedMembershipOffer === "undefined" ||
            Boolean(isReceivedMembershipOffer)
        ) {
            return false;
        }

        const queryString = getUrlVars();
        const canReactive = sessionStorage.getItem("reactiveWithoutPromo");
        if (
            ((queryString.source && queryString.source === "qr-code") ||
                (canReactive && canReactive === "true")) &&
            ["ended"].includes(get(data, "avironSubscription.state")) &&
            get(data, "avironSubscription.plan.type") === "stripe" &&
            get(data, "avironSubscription.plan.isRecurring") &&
            get(data, "avironSubscription.remark.reactive") !== true &&
            isOwnerMembership
        ) {
            const isShowNoticeReactive = sessionStorage.getItem(
                `reactiveWithoutPromo:${params.id}`
            );
            if (!isShowNoticeReactive) {
                setModalState({
                    ...initModalState,
                    openReactiveWithoutPromo: true,
                });
                sessionStorage.setItem(
                    `reactiveWithoutPromo:${params.id}`,
                    "true"
                );
            }
            return true;
        }
        return false;
    }, [data, isCommercialMembership, isReceivedMembershipOffer]);

    useEffect(() => {
        const queryString = getUrlVars();
        if (
            (queryString.action && queryString.action === "fitplan-choose-membership") &&
            ["active"].includes(get(data, "avironSubscription.state")) &&
            (get(data, "avironSubscription.plan.productId") === MembershipProductType.membershipWithFinance
                || get(data, "avironSubscription.plan.productId") === MembershipProductType.membershipWithWarrantyAndFinance)
        ) {
            setModalState({
                ...initModalState,
                openFitPlanChooseMembership:
                    true,
            });
        }
    }, [data]);

    const isFitplan = useMemo(() => {
        return (
            ["active"].includes(get(data, "avironSubscription.state")) &&
            (get(data, "avironSubscription.plan.productId") === MembershipProductType.membershipWithFinance
                || get(data, "avironSubscription.plan.productId") === MembershipProductType.membershipWithWarrantyAndFinance)
        );
        // return false;
    }, [data]);

    const handleCloseNoticeReactiveWithoutPromoModal = () => {
        sessionStorage.setItem(`reactiveWithoutPromo:${params.id}`, "true");
        setModalState(initModalState);
    };

    const isCancelOffer = useMemo(() => {
        if (isFreeMembership) return false;

        if (data) {
            return (
                get(data, "avironSubscription.remark.cancel_offer") &&
                get(data, "avironSubscription.remark.cancel_offer_until") &&
                moment(
                    get(data, "avironSubscription.remark.cancel_offer_until")
                ).isAfter(moment()) &&
                isOwnerMembership
            );
        }
        return false;
    }, [data, isOwnerMembership]);

    const isSubscriptionDiscount = useMemo(() => {
        // if (isFreeMembership) return false;

        if (data) {
            return get(data, "avironSubscription.remark.subscription_discount")
                // && get(data, "avironSubscription.state") === "active"
                && isOwnerMembership
        }
    }, [data, isOwnerMembership])

    const isDuringCancelOffer = useMemo(() => {
        // if (isFreeMembership) return false;

        if (data) {
            return (
                get(data, "avironSubscription.remark.cancel_offer") &&
                get(data, "avironSubscription.remark.cancel_offer_until") &&
                moment(
                    get(data, "avironSubscription.remark.cancel_offer_until")
                ).isAfter(moment()) &&
                get(data, "avironSubscription.remark.cancel_offer_from") &&
                moment(
                    get(data, "avironSubscription.remark.cancel_offer_from")
                ).isBefore(moment()) &&
                isOwnerMembership
            );
        }
        return false;
    }, [data]);

    const handleChangePage = (_page: number) => {
        setPagination(set(pagination, "currentPage", _page));
        refetchReceipts({
            id: params.id,
            first: ROWS_PER_PAGE,
            offset: _page * ROWS_PER_PAGE,
        });
    };

    const receipts =
        React.useMemo(() => {
            if (receiptsData && receiptsData.subscriptionReceipts) {
                return receiptsData.subscriptionReceipts.nodes.map(
                    (node: any, index: number) => {
                        let isShowStar: boolean = false;

                        if (
                            index ===
                            (receiptsData?.subscriptionReceipts?.totalCount ||
                                0) -
                            1
                        ) {
                            isShowStar = false;
                        } else if (isCancelOffer) {
                            if (
                                moment(
                                    get(
                                        data,
                                        "avironSubscription.remark.cancel_offer_until"
                                    )
                                ).isAfter(moment(node.periodStart)) &&
                                moment(
                                    get(
                                        data,
                                        "avironSubscription.remark.cancel_offer_from"
                                    )
                                ).isBefore(moment(node.periodStart))
                            ) {
                                isShowStar = true;
                            }
                            // else if (isShowStar === true) {
                            //     isShowStar = false;
                            // } else {
                            //     isShowStar = true;
                            // }
                        }
                        if (node?.note === "reactive") {
                            isShowStar = true;
                        }

                        return {
                            createdAt: moment(node.createdAt).format(
                                "MM/DD/YYYY"
                            ),
                            periodStart: node.periodStart
                                ? moment(node.periodStart).format("MM/DD/YYYY")
                                : "---",
                            periodEnd: node.periodEnd
                                ? moment(node.periodEnd).format("MM/DD/YYYY")
                                : "---",
                            amount: node.amount / 100,
                            status: capitalizeFirstLetter(node.status),
                            isShowStar: isShowStar,
                        };
                    }
                );
            }
        }, [receiptsData, data]) || [];

    const totalCount =
        React.useMemo(() => {
            if (receiptsData && receiptsData.subscriptionReceipts) {
                return receiptsData.subscriptionReceipts.totalCount;
            }
        }, [receiptsData]) || 0;

    const getAvironKey = (token: string = " ") => {
        return token;
    };

    const ModelCell = useMemo(() => {
        // (models: string[]) => {
        if (membershipData?.appIds?.length > 0) {
            const appIds = formatAppIds(membershipData?.appIds);
            let res = "";
            for (let i = 0; i < appIds.length; i++) {
                if (i !== appIds.length - 1) {
                    res = res + getAppItem(appIds[i]).value + ", ";
                } else {
                    res = res + getAppItem(appIds[i]).value;
                }
            }
            return res;
        }
    }, [membershipData?.appIds]);

    const submitChangeSubscriptionPlan = async (planId: number) => {
        setState(
            Object.assign(state, {
                processing_change_plan: true,
            })
        );
        setModalState({ ...initModalState, openChange: false });
        Swal.showLoading();
        const successFunc = async function () {
            await Swal.fire(
                "Success",
                "Membership plan changed successfully.",
                "success"
            );
            // refetch();
            // setTimeout(() => {
            //     refetchReceipts({
            //         id: params.id,
            //         first: ROWS_PER_PAGE,
            //         offset: 0,
            //     });
            // }, 2000);
            window.location.reload();
        };
        const errorFunc = async (
            message: string = "Change membership plan failed"
        ) => {
            await Swal.fire("Error", message, "error");
            // refetch();
            // refetchReceipts({
            //     id: params.id,
            //     first: ROWS_PER_PAGE,
            //     offset: 0,
            // });
            window.location.reload();
        };
        try {
            const { data } = await changeUserSubscription({
                variables: {
                    id: params.id,
                    planId,
                },
            });
            if (
                data &&
                data.changeUserSubscriptionPlan &&
                (data as any).changeUserSubscriptionPlan.error
            ) {
                errorFunc(
                    "Can't charge your card. We will open a new tab automatically to retry payment for membership. Please reload page after payment successful."
                );
                setState(
                    Object.assign(state, {
                        change_plan_invoice_url: (data as any)
                            .changeUserSubscriptionPlan.error.invoice
                            .hosted_invoice_url,
                        processing_change_plan: false,
                    })
                );
                setTimeout(() => {
                    window.open(
                        (data as any)?.changeUserSubscriptionPlan?.error.invoice
                            .hosted_invoice_url
                    );
                }, 3000);
            } else {
                successFunc();
            }
        } catch (err) {
            errorFunc();
        } finally {
            setState({
                ...state,
                processing_change_plan: false,
            });
        }
    };

    const submitKlaviyoUpgradeSubscriptionPlan = async (planId: number) => {
        setState(
            Object.assign(state, {
                processing_change_plan: true,
            })
        );
        setModalState({ ...initModalState });
        Swal.showLoading();
        const successFunc = async function () {
            setModalState({ ...initModalState, openUpgradePlanSuccess: true })
            sessionStorage.setItem(`isUpgradePlan-${params.id}`, "1")
            refetch();
            setTimeout(() => {
                refetchReceipts({
                    id: params.id,
                    first: ROWS_PER_PAGE,
                    offset: 0,
                });
            }, 2000);

        };
        const errorFunc = async (
            message: string = "Change membership plan failed"
        ) => {
            await Swal.fire("Error", message, "error");
            // refetch();
            // refetchReceipts({
            //     id: params.id,
            //     first: ROWS_PER_PAGE,
            //     offset: 0,
            // });
            window.location.reload();
        };
        try {
            const { data } = await changeUserSubscription({
                variables: {
                    id: params.id,
                    planId,
                },
            });
            Swal.close();
            if (
                data &&
                data.changeUserSubscriptionPlan &&
                (data as any).changeUserSubscriptionPlan.error
            ) {
                errorFunc(
                    "Can't charge your card. We will open a new tab automatically to retry payment for membership. Please reload page after payment successful."
                );
                setState(
                    Object.assign(state, {
                        change_plan_invoice_url: (data as any)
                            .changeUserSubscriptionPlan.error.invoice
                            .hosted_invoice_url,
                        processing_change_plan: false,
                    })
                );
                setTimeout(() => {
                    window.open(
                        (data as any)?.changeUserSubscriptionPlan?.error.invoice
                            .hosted_invoice_url
                    );
                }, 3000);
            } else {
                successFunc();
            }
        } catch (err) {
            errorFunc();
        } finally {
            setState({
                ...state,
                processing_change_plan: false,
            });
        }
    };

    const submitEnrollLifetimeWarrantyPlan = async (planId: number) => {
        setState(
            Object.assign(state, {
                processing_change_plan: true,
            })
        );
        setModalState({ ...initModalState, openChange: false });
        Swal.showLoading();
        const successFunc = async function () {
            await Swal.fire(
                "Success",
                "Membership enrolled successfully",
                "success"
            );
            // refetch();
            // refetchReceipts({
            //     id: params.id,
            //     first: ROWS_PER_PAGE,
            //     offset: 0,
            // });
            window.location.reload();
        };
        const errorFunc = async (
            message: string = "Membership enroll failed"
        ) => {
            await Swal.fire("Error", message, "error");
            // refetch();
            // refetchReceipts({
            //     id: params.id,
            //     first: ROWS_PER_PAGE,
            //     offset: 0,
            // });
            window.location.reload();
        };
        try {
            const { data } = await changeUserSubscription({
                variables: {
                    id: params.id,
                    planId,
                },
            });
            if (
                data &&
                data.changeUserSubscriptionPlan &&
                (data as any).changeUserSubscriptionPlan.error
            ) {
                errorFunc(
                    "Can't charge your card. We will open a new tab automatically to retry payment for membership. Please reload page after payment successful."
                );
                setState(
                    Object.assign(state, {
                        change_plan_invoice_url: (data as any)
                            .changeUserSubscriptionPlan.error.invoice
                            .hosted_invoice_url,
                        processing_change_plan: false,
                    })
                );
                setTimeout(() => {
                    window.open(
                        (data as any)?.changeUserSubscriptionPlan?.error.invoice
                            .hosted_invoice_url
                    );
                }, 3000);
            } else {
                successFunc();
            }
        } catch (err) {
            errorFunc();
        } finally {
            setState({
                ...state,
                processing_change_plan: false,
            });
        }
    };

    const submitAcceptCancelOfferSubscription = async () => {
        // setModalState({ ...initModalState, openCancelOffer: false });
        setModalState({ ...initModalState, openCancelOfferSuccess: false });

        Swal.showLoading();
        const successFunc = async function () {
            Swal.close();
            setModalState({ ...initModalState, openCancelOfferSuccess: true });
            refetch();
            refetchReceipts();
            // window.location.reload();
        };
        const errorFunc = async (message: string = "Claim offer failed") => {
            await Swal.fire("Claim offer error", message, "error");
            refetch();
            refetchReceipts();
            // window.location.reload();
        };
        try {
            // reason: pause reason
            await claimCancelOffer({
                variables: {
                    id: params.id,
                },
            });
            refetchCheckCancelOfferData();

            successFunc();
        } catch (err) {
            errorFunc(err.message.replace("GraphQL error: ", ""));
        }
    };

    const submitResumeSubscription = async () => {
        setModalState({ ...initModalState, openPause: false });
        Swal.showLoading();
        const successFunc = async function () {
            await Swal.fire(
                "Success",
                "Membership resumed successfully.",
                "success"
            );
            refetch();
            refetchReceipts();
        };
        const errorFunc = async (
            message: string = "Resume membership failed"
        ) => {
            await Swal.fire("Error", message, "error");
            refetch();
            refetchReceipts();
        };
        try {
            // reason: pause reason
            await resumePaymentSubscription({
                variables: {
                    id: params.id,
                },
            });
            successFunc();
        } catch (err) {
            errorFunc();
        }
    };

    const isMonthlyMembership = useMemo(
        () =>
            get(data, "avironSubscription.plan.interval") ===
            MEMBERSHIP_INTERVAL.MONTHLY,
        [data]
    );
    const isAnnualMembership = useMemo(
        () =>
            get(data, "avironSubscription.plan.interval") ===
            MEMBERSHIP_INTERVAL.YEARLY,
        [data]
    );

    const isDuringFreeOffer: boolean = useMemo(() => {
        const offerUntil = get(data, "avironSubscription.remark.offer_until");
        if (!offerUntil) return false;

        const offerUntilNumber = Number(offerUntil);
        if (isNaN(offerUntilNumber)) return false;

        return moment().isBefore(moment.unix(offerUntilNumber));
    }, [data]);

    const isCanChange = () => {
        const isGoModel = get(data, "avironSubscription.plan")?.appIds.includes(CONSTANTS.APP.AVIRON_GO);
        const isAnnual = get(data, "avironSubscription.plan.interval") === MEMBERSHIP_INTERVAL.YEARLY;
        const isCommercial = get(data, "avironSubscription.plan.customerType") === "commercial";
        if (
            (get(data, "avironSubscription.state") === "inactive" &&
                !!get(data, "avironSubscription.machineId")) || (isGoModel && isAnnual)
        ) {
            return false;
        }
        if (isCommercial) {
            return false
        }
        if (isFreeMembership && ["inactive"].includes(
            get(data, "avironSubscription.state")
        )) {
            return false;
        }
        if (get(data, "avironSubscription.remark.reactive") && isDuringFreeOffer && get(data, "avironSubscription.remark.isAnnual") !== "true") {
            return false;
        }
        return (
            ["active", "inactive"].includes(
                get(data, "avironSubscription.state")
            ) &&
            get(data, "avironSubscription.plan.type") === "stripe" &&
            get(data, "avironSubscription.plan.isRecurring") &&
            get(data, "avironSubscription.plan.productId") !==
            MembershipProductType.membershipWithFinance &&
            get(data, "avironSubscription.plan.productId") !==
            MembershipProductType.membershipWithWarrantyAndFinance &&
            isOwnerMembership
        );
    };
    const isCanPause = () => {
        if (isFreeMembership) return false;

        if (get(data, "avironSubscription.remark.reactive") && isDuringFreeOffer) {
            return false;
        }

        return (
            ["active"].includes(get(data, "avironSubscription.state")) &&
            get(data, "avironSubscription.plan.type") === "stripe" &&
            get(data, "avironSubscription.plan.isRecurring") &&
            !get(data, "avironSubscription.isPauseCollection") &&
            get(data, "avironSubscription.plan.interval") !== "yearly" &&
            get(data, "avironSubscription.plan.customerType") === "personal" &&
            get(data, "avironSubscription.plan.productId") !==
            MembershipProductType.membershipWithFinance &&
            get(data, "avironSubscription.plan.productId") !==
            MembershipProductType.membershipWithWarrantyAndFinance &&
            isOwnerMembership
        );
    };
    const isCanResume = () => {
        if (isFreeMembership) return false;

        return (
            ["active", "inactive"].includes(
                get(data, "avironSubscription.state")
            ) &&
            get(data, "avironSubscription.plan.type") === "stripe" &&
            get(data, "avironSubscription.plan.isRecurring") &&
            get(data, "avironSubscription.isPauseCollection") &&
            isOwnerMembership
        );
    };

    const isCanCancel = () => {
        return (
            ["active"].includes(get(data, "avironSubscription.state")) &&
            get(data, "avironSubscription.plan.type") === "stripe" &&
            get(data, "avironSubscription.plan.isRecurring") &&
            get(data, "avironSubscription.plan.productId") !==
            MembershipProductType.membershipWithFinance &&
            get(data, "avironSubscription.plan.productId") !==
            MembershipProductType.membershipWithWarrantyAndFinance &&
            isOwnerMembership
        );
    };

    const isCanEnrollLifetime = () => {
        return false;
        // return (
        //     ["active", "inactive"].includes(
        //         get(data, "avironSubscription.state")
        //     ) &&
        //     get(data, "avironSubscription.plan.type") === "stripe" &&
        //     get(data, "avironSubscription.plan.isRecurring") &&
        //     data?.avironSubscription?.plan?.productId?.toString() ===
        //     MembershipProductType.membership &&
        //     data?.avironSubscription?.plan?.customerType === "personal" &&
        //     data?.avironSubscription?.plan?.currency === "cad" &&
        //     data?.avironSubscription?.plan?.productId.toString() !==
        //     MembershipProductType.membershipWithFinance &&
        //     data?.avironSubscription.plan.productId.toString() !==
        //     MembershipProductType.membershipWithWarrantyAndFinance
        // );
        // return true
    };

    const handleOpenEnrollLifetimeWarranty = () => {
        setModalState({ ...initModalState, openEnrollLifetimeWarranty: true });
        return;
    };

    const handleOpenCancel = () => {
        setModalState({ ...initModalState, openCancel: true });
        trackingClickCancel();
        return;
    };

    const trackingClickCancel = () => {
        trackingSubscriptionAction({
            variables: {
                id: data?.avironSubscription?.id,
                action: "click_cancel_button",
                payload: {
                    customer_id: data?.avironSubscription?.customerId,
                    customer_type: "user",
                    source: "cms",
                },
            },
        });
    };

    const handleChooseCancel = () => {
        if (checkCancelOfferData?.checkCancelOfferAvailableSubscription) {
            setModalState({
                ...initModalState,
                openCancelOffer: true,
                cancelData: modalState.cancelData,
            });
        } else {
            handleCancelMembership();
        }
    };

    const handleCancelMembership = async () => {
        const successFunc = async function () {
            const res = await Swal.fire(
                "Success",
                "Membership canceled successfully.",
                "success"
            );
            if (!res.isConfirmed) {
                return;
            }
            // refetch();
            // refetchReceipts();
            window.location.reload();
        };
        const errorFunc = async (
            message: string = "Cancel membership failed"
        ) => {
            await Swal.fire("Error", message, "error");
            refetch();
            refetchReceipts();
            window.location.reload();
        };
        try {
            await cancelUserSubscription({
                variables: {
                    id: params.id,
                    reason: modalState?.cancelData?.reason || "",
                    reasonDetail: modalState?.cancelData?.reasonDetail || "",
                },
            });

            successFunc();
        } catch (err) {
            errorFunc();
        } finally {
            setModalState(initModalState);
        }
    };

    const handleSubmitReactive = async () => {
        resetState();
        Swal.showLoading();
        const successFunc = async function () {
            await Swal.fire(
                "Success",
                "Membership successfully reactivated.",
                "success"
            );
            setIsReCall(true);
            refetch();
            refetchReceipts();
        };
        const errorFunc = async () => {
            await Swal.fire(
                "Oops",
                `
                It seem like you’re not eligible for
                this offer.
                `,
                "error"
            );

            refetch();
            refetchReceipts();
            window.location.reload();
        };
        const source = sessionStorage.getItem("reactiveSource") || undefined;
        try {
            if (source === "klaviyo-email") {
                await reactiveUserSubscriptionV2({
                    variables: {
                        id: params.id,
                        isEnoughTime: false
                    }
                })
            } else {
                await reactiveUserSubscription({
                    variables: {
                        id: params.id,
                        isHavePromo: isCanReactive,
                        source,
                    },
                });
            }

            successFunc();
        } catch (err) {
            console.log("err: ", err.message);
            errorFunc();
        } finally {
            setModalState(initModalState);
        }
    };

    const handleSubmitFitplanChooseSubscription = async (planId: number) => {
        Swal.showLoading();
        const successFunc = async function () {
            await Swal.fire(
                "Success",
                "Membership changed successfully.",
                "success"
            );
            refetch();
            refetchReceipts({
                id: params.id,
                first: ROWS_PER_PAGE,
                offset: 0,
            });
        };
        const errorFunc = async (
            message: string = "Change membership plan failed"
        ) => {
            await Swal.fire("Error", message, "error");
            refetch();
            refetchReceipts({
                id: params.id,
                first: ROWS_PER_PAGE,
                offset: 0,
            });
        };
        try {
            await fitplanChooseSubscription({
                variables: {
                    id: params.id,
                    planId,
                },
            });
            successFunc();
        } catch (err) {
            errorFunc();
        } finally {
            setModalState(initModalState);
        }
    };

    if (loading || receiptLoading || loadingPlan) {
        return (
            <div style={{ height: "100vh" }}>
                <AvironLoading />
            </div>
        );
    }

    return (
        <div className={style["MembershipDetail"]}>
            <Box
                padding={{
                    xs: "16px 32px",
                    md: 0,
                }}
                className={style["membershipBoxWrapper"]}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Membership detail | Aviron</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <GoBack url={location?.pathname} />
                <Stack direction={{ xs: "column-reverse", md: "column" }}>
                    <Box mt="24px">
                        <Grid container>
                            <Grid item md={3}>
                                <PageTitle>Membership detail</PageTitle>
                            </Grid>
                            <Grid item xs={12} md={9} textAlign="end">
                                <Stack
                                    spacing={2}
                                    direction={{ xs: "column", md: "row" }}
                                    alignItems="end"
                                    justifyContent="flex-end"
                                >
                                    {isCanEnrollLifetime() && (
                                        <div
                                            className="enroll-lifetime-container"
                                            style={{ position: "relative" }}
                                        >
                                            <div
                                                className="limited-time"
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 1,
                                                    background:
                                                        "var(--Neutral-900, #161616)",
                                                    color: "#fff",
                                                    left: "8px",
                                                    top: "-10px",
                                                    borderRadius: "100px",
                                                    padding: "2px 6px",
                                                    border: "solid 2px",
                                                    fontSize: "12px",
                                                    fontWeight: 600,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "4px",
                                                }}
                                            >
                                                <svg
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10ZM6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                                        fill="white"
                                                    />
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M5.25 3.5C5.25 3.22386 5.47386 3 5.75 3C6.02614 3 6.25 3.22386 6.25 3.5V6.22288L7.591 7.061C7.82517 7.20736 7.89636 7.51583 7.75 7.75C7.60364 7.98417 7.29517 8.05536 7.061 7.909L5.72 7.07087C5.42762 6.88814 5.25 6.56767 5.25 6.22288V3.5Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                                LIMITED TIME
                                            </div>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={
                                                    handleOpenEnrollLifetimeWarranty
                                                }
                                            >
                                                Enroll in Lifetime Warranty
                                            </Button>
                                        </div>
                                    )}
                                    {isCanReactive && (
                                        <div
                                            className="enroll-lifetime-container"
                                            style={{ position: "relative" }}
                                        >
                                            <div
                                                className="limited-time"
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 1,
                                                    background:
                                                        "var(--Neutral-900, #161616)",
                                                    color: "#fff",
                                                    left: "8px",
                                                    top: "-10px",
                                                    borderRadius: "100px",
                                                    padding: "2px 6px",
                                                    border: "solid 2px",
                                                    fontSize: "12px",
                                                    fontWeight: 600,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "4px",
                                                }}
                                            >
                                                <svg
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10ZM6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                                        fill="white"
                                                    />
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M5.25 3.5C5.25 3.22386 5.47386 3 5.75 3C6.02614 3 6.25 3.22386 6.25 3.5V6.22288L7.591 7.061C7.82517 7.20736 7.89636 7.51583 7.75 7.75C7.60364 7.98417 7.29517 8.05536 7.061 7.909L5.72 7.07087C5.42762 6.88814 5.25 6.56767 5.25 6.22288V3.5Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                                LIMITED TIME
                                            </div>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    setModalState({
                                                        ...initModalState,
                                                        openReactive: true,
                                                    });
                                                }}
                                            >
                                                START 30 DAYS FREE
                                            </Button>
                                        </div>
                                    )}
                                    {isFitplan && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                setModalState({
                                                    ...initModalState,
                                                    openFitPlanChooseMembership:
                                                        true,
                                                });
                                            }}
                                        >
                                            Change Membership
                                        </Button>
                                    )}
                                    {isCanReactiveWithoutPromo && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                setModalState({
                                                    ...initModalState,
                                                    openReactiveWithoutPromo:
                                                        true,
                                                });
                                            }}
                                        >
                                            Reactivate
                                        </Button>
                                    )}
                                    {isCanChange() && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                // setModalState({
                                                //     ...initModalState,
                                                //     openChange: true,
                                                // });
                                                history.push(`/membership/${params.id}/upgrade`);
                                            }}
                                        >
                                            CHANGE MEMBERSHIP
                                        </Button>
                                    )}
                                    {/* {isCanPause() && isOwnerMembership && (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                setModalState({
                                                    ...initModalState,
                                                    openPause: true,
                                                });
                                            }}
                                        >
                                            PAUSE MEMBERSHIP
                                        </Button>
                                    )} */}
                                    {isCanResume() && (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                setModalState({
                                                    ...initModalState,
                                                    openResume: true,
                                                });
                                            }}
                                        >
                                            RESUME MEMBERSHIP
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Paper
                        sx={{
                            padding: "32px 38px",
                            margin: "24px 0",
                            position: "relative",
                        }}
                        className={style["memberPaper"]}
                    >
                        <Grid
                            container
                            display="flex"
                            justifyContent="space-between"
                            spacing={2}
                            direction={{
                                xs: "column",
                                sm: "column",
                                md: "row",
                            }}
                        >
                            <Grid item xs={8} sm={12} md={7}>
                                <Typography
                                    variant="h3"
                                    mb="32px"
                                    sx={{
                                        fontFamily: "Work Sans",
                                        fontWeight: 400,
                                    }}
                                >
                                    {membershipData.planName}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={12} md={5}>
                                {isOwnerMembership && (
                                    // (isAvironBikeApp ||
                                    //     isAvironRowerApkApp) &&
                                    <div className={style["membershipLink"]}>
                                        <>
                                            {!membershipData?.appIds?.includes(
                                                CONSTANTS.APP.AVIRON_GO
                                            ) &&
                                                !membershipData?.appIds?.includes(
                                                    CONSTANTS.APP.BIKE_GO
                                                ) && (
                                                    <MemberLink
                                                        link={`${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_MACHINE_MANAGE}`.replace(
                                                            ":id",
                                                            params.id
                                                        )}
                                                        text={
                                                            `Products` +
                                                            (membershipData
                                                                ?.constrainTypes
                                                                ?.max_machine
                                                                ? ` ${membershipData?.totalMachines}/${membershipData?.constrainTypes?.max_machine}`
                                                                : "")
                                                        }
                                                    />
                                                )}
                                        </>
                                        <>
                                            {(membershipData?.appIds?.includes(
                                                CONSTANTS.APP.AVIRON_GO
                                            ) ||
                                                membershipData?.appIds?.includes(
                                                    CONSTANTS.APP.BIKE_GO
                                                )) &&
                                                membershipData?.type ===
                                                "warranty" ? (
                                                <></>
                                            ) : (
                                                <MemberLink
                                                    link={`${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_INVITE_LIST}`.replace(
                                                        ":id",
                                                        params.id
                                                    )}
                                                    text={
                                                        `Member List` +
                                                        (membershipData
                                                            ?.constrainTypes
                                                            ?.max_user_family
                                                            ? ` ${membershipData?.totalMembers}/${membershipData?.constrainTypes?.max_user_family}`
                                                            : "")
                                                    }
                                                />
                                            )}
                                        </>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            display="flex"
                            justifyContent="space-between"
                            spacing={2}
                            direction={{
                                xs: "column",
                                sm: "column",
                                md: "row",
                            }}
                            paddingBottom={{ xs: "32px" }}
                        >
                            <Grid item xs={12} sm={4} md={2}>
                                <Typography variant="subtitle2">
                                    Membership Key
                                </Typography>
                                <Typography variant="body1">
                                    {getAvironKey(membershipData.orgKey)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <Typography variant="subtitle2">
                                    Owner Email
                                </Typography>
                                <Typography variant="body1">
                                    {get(membershipData, "owner.email")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <Typography variant="subtitle2">
                                    Status
                                </Typography>
                                <Typography variant="body1">
                                    {
                                        getStatus(
                                            String(membershipData.status),
                                            membershipData?.isPauseCollection
                                        )?.name
                                    }
                                </Typography>
                                {get(data, "avironSubscription.resumeAt") &&
                                    data?.avironSubscription
                                        ?.isPauseCollection &&
                                    data?.avironSubscription.state !==
                                    "ended" && (
                                        <Typography variant="body1">
                                            Until{" "}
                                            {moment(
                                                get(
                                                    data,
                                                    "avironSubscription.resumeAt"
                                                )
                                            ).format("MM/DD/YYYY")}
                                        </Typography>
                                    )}
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <Typography variant="subtitle2">
                                    Start
                                </Typography>
                                <Typography variant="body1">
                                    {get(membershipData, "startAt")
                                        ? moment
                                            .unix(
                                                get(membershipData, "startAt")
                                            )
                                            .format("MM/DD/YYYY")
                                        : "---"}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={4} md={2}>
                                <Typography variant="subtitle2">
                                    Machine ID
                                </Typography>
                                <Typography variant="body1">
                                    {get(
                                        data,
                                        "avironSubscription.machineId"
                                    ) || "---"}
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12} sm={4} md={2}>
                                <Typography variant="subtitle2">
                                    Model
                                </Typography>
                                <Typography variant="body1">
                                    {ModelCell}
                                </Typography>
                            </Grid>
                        </Grid>
                        {get(data, "avironSubscription.plan.type") ===
                            "stripe" &&
                            isOwnerMembership && (
                                <>
                                    <Divider
                                        sx={{
                                            position: "absolute",
                                            left: 0,
                                            width: "100%",
                                        }}
                                        className={style["divider"]}
                                    />
                                    <Box
                                        mt="16px"
                                        className={style["ReceiptsWrapper"]}
                                    >
                                        <Typography
                                            variant="h4"
                                            color="#808080"
                                        >
                                            Receipts
                                        </Typography>
                                        <Receipts
                                            receipts={receipts}
                                            totalCount={totalCount}
                                            onPageChange={handleChangePage}
                                            pageSize={ROWS_PER_PAGE}
                                            currentPage={pagination.currentPage}
                                        />
                                    </Box>
                                </>
                            )}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexDirection: windowSize.width < 768 ? "column" : "row",
                            }}
                        >
                            <div>
                                {isCancelOffer ? (
                                    <p
                                        style={{
                                            textAlign:
                                                windowSize.width < 768
                                                    ? "left"
                                                    : "right",
                                            fontSize: 12,
                                            marginBottom: 0,
                                            borderRadius: "4px",
                                            border: "1px solid var(--neutral-300, #C3C3C3)",
                                            background:
                                                "var(--neutral-100, #E6E6E6)",
                                            padding: "8px",
                                        }}
                                    >
                                        {isDuringCancelOffer ? (
                                            <i>
                                                *This is a discounted rate
                                                applicable to the specific time
                                                period
                                            </i>
                                        ) : (
                                            <i>
                                                *You have activated a special
                                                promotional offer and your
                                                membership rate will be discounted
                                                at your next billing cycle
                                            </i>
                                        )}
                                    </p>
                                ) : (
                                    <p></p>
                                )}
                                {isSubscriptionDiscount ? (
                                    <>
                                        <p
                                            style={{
                                                textAlign:
                                                    windowSize.width < 768
                                                        ? "left"
                                                        : "right",
                                                fontSize: 12,
                                                marginBottom: 0,
                                                borderRadius: "4px",
                                                border: "1px solid var(--neutral-300, #C3C3C3)",
                                                background:
                                                    "var(--neutral-100, #E6E6E6)",
                                                padding: "8px",
                                                display: "inline-block"
                                            }}
                                        >
                                            <i>
                                                *This is a discounted rate applicable to the specific time period
                                            </i>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}
                            </div>
                            <div>
                                <Grid container gap={"16px"}>
                                    {/* {isCanEnrollLifetime() && <UnderlineButton onClick={handleOpenEnrollLifetimeWarranty} title="Enroll in Lifetime Membership" />} */}

                                    {isCanCancel() && (isAnnualMembership || isFreeMembership || (get(data, "avironSubscription.remark.reactive") && isDuringFreeOffer)) && (
                                        <UnderlineButton
                                            title="CANCEL MEMBERSHIP"
                                            onClick={() => {
                                                handleTrackingCancellationInitiated()
                                                trackingCancelMembershipClick(
                                                    data as GetAvironSubscriptionQuery
                                                );
                                                history.push(
                                                    `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_CANCEL_REASONS}?id=${params.id}`
                                                );
                                            }}
                                        />
                                    )}
                                    {isCanPause() && isMonthlyMembership && (
                                        <UnderlineButton
                                            title="PAUSE OR CANCEL MEMBERSHIP"
                                            onClick={() => {
                                                handleTrackingCancellationInitiated()
                                                trackingPauseOrCancelMembershipClick(
                                                    data as GetAvironSubscriptionQuery
                                                );
                                                history.push(
                                                    `${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_PAUSE_OR_CANCEL}?id=${params.id}`
                                                );
                                            }}
                                        />
                                    )}
                                </Grid>
                            </div>
                        </div>

                        <ChangeMembershipModal
                            open={modalState.openChange}
                            onClose={resetState}
                            state={state}
                            onSubmit={submitChangeSubscriptionPlan}
                            plans={planData?.plans?.nodes || []}
                            membership={data?.avironSubscription}
                        />
                        <KlaviyoUpgradeMembershipModal
                            open={modalState.openUpgradePlan}
                            onClose={resetState}
                            state={state}
                            onSubmit={submitKlaviyoUpgradeSubscriptionPlan}
                            plans={planData?.plans?.nodes || []}
                            membership={data?.avironSubscription}
                        />
                        <KlaviyoUpgradeMembershipSuccessModal
                            open={modalState.openUpgradePlanSuccess}
                            onClose={resetState}
                        />

                        <ResumeMembershipModal
                            open={modalState.openResume}
                            onClose={resetState}
                            onSubmit={submitResumeSubscription}
                        />
                        <CancelOfferMembershipModal
                            open={modalState.openCancelOffer}
                            onClose={resetState}
                            onSubmit={submitAcceptCancelOfferSubscription}
                            planId={data?.avironSubscription?.planId}
                            onCancel={handleCancelMembership}
                        />
                        <EnrollLifetimeWarrantyModal
                            open={modalState.openEnrollLifetimeWarranty}
                            onClose={resetState}
                            state={state}
                            onSubmit={submitEnrollLifetimeWarrantyPlan}
                            plans={planData?.plans?.nodes || []}
                            membership={data?.avironSubscription}
                        />
                        <ReactiveMembershipModal
                            open={modalState.openReactive}
                            onClose={handleCloseNoticeReactiveModal}
                            onSubmit={handleSubmitReactive}
                        />
                        <ReactiveMembershipWithoutPromoModal
                            open={modalState.openReactiveWithoutPromo}
                            onClose={handleCloseNoticeReactiveWithoutPromoModal}
                            onSubmit={handleSubmitReactive}
                        />
                        <FitPlanChooseMembershipModal
                            open={modalState.openFitPlanChooseMembership}
                            onClose={resetState}
                            state={state}
                            onSubmit={handleSubmitFitplanChooseSubscription}
                            plans={planData?.plans?.nodes || []}
                            membership={data?.avironSubscription}
                        />
                    </Paper>
                </Stack>
            </Box>
            {/* {(loading || receiptLoading) && <AvironLoading />} */}
        </div>
    );
}
